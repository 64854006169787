.btn-reset-international:hover{
    background-color: red !important;
}

.btn-reset-international:hover>.eraser{
    color: white;
}

.btn-spinner-international:hover{
    background-color: #1cbb8c !important;
}

.btn-spinner-international:hover>.search{
    color: white;
}
.btn-filter:hover{
    color: #1cbb8c !important;
    border-bottom: 2px solid #1cbb8c !important;
}

.table-domain-premium>.rt-table>.rt-thead{
    background-color: #1cbb8c;
}

.table-domain-premium>.rt-table>.rt-thead>.rt-tr>.rt-th>.rt-resizable-header-content{
    color: white;
}

.table-domain-premium>.rt-table>.rt-body>.rt-tr-group>.rt-tr>.rt-td{
    background-color: white;
}
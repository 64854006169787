.card {
  border-radius: 8px !important; }

table {
  width: 100%; }

th {
  height: 40px;
  border-bottom: 1px solid;
  font-size: 12pt;
  font-weight: 900; }

tbody > tr > td {
  height: 30px;
  padding: 8px 0; }

.customModalHeader {
  background-color: #dbedff; }

.disabledInput {
  background-color: #f0f0f0 !important;
  color: #666; }

.editIcon {
  color: #2c6af4;
  font-size: 24px;
  cursor: pointer;
  margin-right: 5px; }

.deleteIcon {
  color: #ff3d60;
  font-size: 24px;
  cursor: pointer; }

.infoAlert {
  text-align: right;
  margin-bottom: 0;
  margin-right: -50px; }

.customCard {
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  margin-top: -1rem; }

.custom-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 16px; }

th, td {
  border: 1px solid #f9f9f9;
  padding: 8px;
  word-wrap: break-word;
  max-width: 26rem;
  overflow-y: auto;
  font-size: 16px; }

th {
  background-color: #dbedff;
  text-align: left;
  height: auto !important; }

tbody tr:nth-child(even) {
  background-color: #e5e5e5; }

tbody tr:hover {
  background-color: #ccc; }

td:last-child {
  text-align: center; }

@media screen and (max-width: 768px) {
  .custom-table {
    font-size: 14px; }
  .Pagination-new {
    font-size: 10px !important; } }

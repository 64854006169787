/*color name*/
/*color gradient background*/
/*breakpoint*/
.layout-auth > .Card .error-message {
  color: #002144; }

.layout-auth > .Card > form.login-form input {
  border: 1px solid #ffbaa2; }
  .layout-auth > .Card > form.login-form input::placeholder {
    color: #ffbaa2; }
  .layout-auth > .Card > form.login-form input.is-invalid {
    border-color: #dc3545 !important; }

.layout-auth > .Card > form.login-form a {
  color: #ff817a;
  text-transform: uppercase; }
  .layout-auth > .Card > form.login-form a:hover {
    text-decoration: none; }

form.login-form .input-group .input-custom {
  border-right: 0px transparent solid;
  width: calc(100% - 35px);
  flex: inherit;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px; }

form.login-form .input-group .input-addon-custom {
  border: 1px solid #ced4da;
  border-left: 0px transparent solid;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  width: 35px; }

form.login-form .input-group .form-control:focus + .input-addon-custom {
  border-color: #80bdff; }

form.login-form .input-group .is-invalid + .input-addon-custom {
  border-color: #dc3545 !important; }

#__next {
  height: auto; }
  #__next .layout-auth .PatternContainer {
    width: 100%; }
    #__next .layout-auth .PatternContainer .content {
      text-align: center; }
      #__next .layout-auth .PatternContainer .content .Card {
        width: 100%;
        margin: 0px;
        box-shadow: rgba(224, 224, 224, 0.5) 0px 8px 14px;
        z-index: 2; }
        #__next .layout-auth .PatternContainer .content .Card .logo-container {
          text-align: center; }
          @media (min-width: 768px) {
            #__next .layout-auth .PatternContainer .content .Card .logo-container {
              text-align: left; } }
        @media (min-width: 768px) {
          #__next .layout-auth .PatternContainer .content .Card {
            width: 640px;
            padding: 32px; } }

.btn-uid {
  color: #ffffff;
  background-color: #fcdada;
  border-color: #fcdada; }

.btn-uid:hover {
  color: #ffffff;
  background-color: #f7c7ca;
  border-color: #f7c7ca; }

.off-canvas {
  width: 350px;
  height: 100%;
  top: 0px;
  right: 0px;
  position: fixed;
  z-index: 1200;
  visibility: hidden;
  transform: translateX(350px);
  transition: transform 0.3s, visibility 0s ease-in-out; }

.off-canvas.open {
  transform: translateX(0px);
  visibility: visible; }

.fade-background {
  width: 100%;
  height: 100%;
  top: 0px;
  right: 0px;
  position: fixed;
  z-index: 1198;
  background-color: black;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out, visibility 0s ease-in-out 0.3s; }

.fade-background.open {
  opacity: 0.3;
  visibility: visible;
  transition: opacity 0.5s ease-in-out; }

.fade-text {
  color: black;
  opacity: 0.3; }

.btn-close:hover > .close {
  color: #d02929; }

.filter, .search {
  color: #505d69; }

.filter-button:hover > .filter, .search-button:hover > .search {
  color: #2c6af4; }

.filter-button:hover, .search-button:hover {
  background-color: #dddddd; }

#filter {
  margin-top: 20px;
  margin-bottom: 30px; }

#filter > .juara > .cs-select {
  width: 100% !important;
  margin: 0; }

#filter > .juara > .cs-select > fieldset {
  border-radius: 8px;
  border-color: #ced4da; }

#filter > .juara > .cs-select > div > #select-domain_status_id {
  color: #505d69 !important;
  font-family: Nunito,sans-serif !important;
  font-size: 14px;
  font-weight: 400; }

#filter > .juara > label {
  color: #505d69 !important;
  font-family: Nunito,sans-serif !important;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0px;
  margin-top: 3px; }

.search-field > #domain > .service_name {
  width: 100% !important;
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important; }

#domain > .service_name {
  width: 100% !important; }

@media screen and (max-width: 378px) {
  .outside-canvas {
    width: 70% !important; } }

@media screen and (max-width: 320px) {
  .outside-canvas {
    width: 65% !important; } }

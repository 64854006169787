/*breakpoint*/
.domain-detail .sidebar {
  background: transparent;
  box-sizing: border-box; }
  .domain-detail .sidebar li a {
    color: #4f4f4f;
    padding-top: 15px;
    padding-bottom: 15px; }
    .domain-detail .sidebar li a.active {
      border-left: 2px solid #4f4f4f;
      background-color: #eef1fb;
      background: #eef1fb; }
    .domain-detail .sidebar li a img {
      width: 18px; }
  .domain-detail .sidebar li:hover a {
    background-color: #eef1fb;
    background: #eef1fb; }

.domain-detail .tab-pane .domain-info p {
  font-family: 'Inconsolata', monospace; }

.search-input > div input {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 8px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  overflow: visible; }
  .search-input > div input:focus {
    outline: none !important;
    box-shadow: none !important; }

.UploadButton .input-upload {
  min-width: 100%; }

.Detail-Document {
  display: flex;
  justify-content: center;
  align-items: center; }
  .Detail-Document .form-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1em; }
  .Detail-Document .uploadBaru {
    border: 1px solid rgba(0, 0, 0, 0.125);
    padding: 1rem;
    text-align: center; }

.no-image {
  max-width: 100%;
  width: 310px;
  height: auto;
  max-height: 100%;
  overflow: hidden;
  position: relative; }
  .no-image::after {
    content: '';
    display: block;
    padding-bottom: 100%;
    background: #f0f3ff; }
  .no-image span {
    position: absolute;
    left: -1000%;
    right: -1000%;
    top: -1000%;
    bottom: -1000%;
    margin: auto; }
  .no-image img {
    position: absolute;
    left: -1000%;
    right: -1000%;
    top: -1000%;
    bottom: -1000%;
    margin: auto;
    max-height: 200%;
    max-width: 200%;
    z-index: 0; }
    .no-image img.selected-image {
      border: 2px blue solid; }

.radius-30 {
  border-radius: 30px;
  padding: 8px 18px; }

.RenewDomain .table thead th {
  border-bottom-width: 1px; }

.mygrid .mygrid-item {
  width: 100%;
  margin-bottom: 1rem; }

.container-gallery-upload {
  overflow-y: scroll; }
  @media (min-width: 768px) {
    .container-gallery-upload {
      overflow-y: unset; } }
  @media (min-width: 768px) {
    .container-gallery-upload .modal-gallery {
      max-width: 80%;
      max-height: 80%; } }

.Pagination-new {
  display: inline-block;
  width: 100%;
  text-align: center; }
  .Pagination-new .list {
    width: fit-content;
    display: inline-block; }

ul.dns-list {
  display: inline-block;
  text-align: center;
  padding: 0px; }
  ul.dns-list li {
    width: fit-content;
    margin: 0px auto;
    list-style: none;
    margin: 0px 10px; }
    @media (min-width: 768px) {
      ul.dns-list li {
        float: left; } }

.button-copy {
  border: 1px solid #ced4da !important;
  border-left: 0px; }

.button-copy:hover {
  background-color: #ededed; }

.btn-manage-domain {
  border: 1px solid #2c6af4 !important;
  text-decoration: none !important;
  width: 100%;
  border-radius: 8px !important; }

.btn-manage-domain:hover {
  background-color: #2c6af4;
  color: white !important; }
